import { find } from '@/api'
export default {
    name: 'Find',

    mounted () {
        this.handleInit()
    },

    watch: {
        // '$route' (v, o) {
        //     if (v.query.sn == o.query.sn) return
        //     this.handleInit()
        // },
        // 'sn' (v, o) {
        //     if (v == o) return
        //     this.handleInit()
        // }
    },

    methods: {
        handleInit () {
            this.$toast.loading({ message: '加载中...', forbidClick: true })
            // if(this.$route.query.sn) {
            //     this.sn = this.$route.query.sn
            //     this.handleSearch()
            //     return 
            // } 
            this.sn = ''
            this.handleGetData(); // 获取初始化数据
        },

        // 获取数据
        handleGetData () {
            let options = {}
            if (this.$route.query.cp) options.cp = this.$route.query.cp
            find.index(options).then(res => {
                this.$toast.clear()
                if(res.status != 1) return this.$toast(res.message)
                this.items = []
                this.items = res.data
                this.info = res.info
            }).catch(err => {
                this.$toast.clear()
                console.log(err)
            })
        },

        // 搜索
        handleSearch () {
            if (this.isAjax) return
            this.isAjax = true
            this.$toast.loading({ message: '加载中...', forbidClick: true })
            find.search({
                sn: this.sn
            }).then(res => {
                this.$toast.clear()
                this.isAjax = false
                if(res.status != 1) return this.$toast(res.message)
                this.items = []
                this.items = [].concat(res.data)
            }).catch(err => {
                this.$toast.clear()
                this.isAjax = false
                console.log(err)
            })
        },

        /**
         * 查看报告
         * @param {Object} args  数据对象
         */
        handleToReport (args) {
            if (args.status == 1 && args.end == 1) return this.$router.push({name: 'Report', query: {sn: args.sn, id: args.product.templates}})
            if (args.status == 1 && args.end != 1) return this.$router.push({name: 'Answer', query: {sn: args.sn}})
            if (args.status == 0 && args.end == 0) return this.$router.push({name: 'Payment', query: {sn: args.sn}})
        },

        // 滚动页面
        handleScrollBottom (bool = true) {
            this.$nextTick(() => {
                let options = { top: document.getElementById('info').clientHeight, left: 0 };
                if (bool) options.behavior = 'smooth'
                window.scroll(options);
            })
        },
    },

    data () {
        return {
            isAjax: false,
            isFinished: false,
            items: {},
            info: '',
            sn: ''
        }
    }
    
}